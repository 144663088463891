'use strict'
import store from '../store'

const service = config.VUE_APP_service_licenze || ''

function getToken() {
  return store.getters['utenti/user'].token || ''
}

async function getServices() {
  try {
    const response = await fetch(`${service}/services`, {
      headers: { 'Content-Type': 'application/json' ,
        Authorization: 'Bearer ' + getToken()
      }
    })
    return await response.json()
  } catch (error) {
    // errorService.onError(error, 'getServices')
    return []
  }
}

async function getModello() {
  const response = await fetch(`${service}/services/modello`)
  return await response.json()
}

async function creaService(nuovoService) {
  try {
    const response = await fetch(`${service}/services/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(nuovoService)
    })
    return await response.json()
  } catch (err) {
    return {}
  }
}

async function modificaService(editedService) {
  try {
    const response = await fetch(`${service}/services/${editedService.codice}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(editedService)
    })
    return await response.json()
  } catch (err) {
    return {}
  }
}

async function setDbInstallato(serviceId, basedb) {
  try {
    const data = {
      serviceId,
      basedb
    }
    const response = await fetch(`${service}/services/installato`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    return await response.json()
  } catch (err) {
    return {}
  }
}

export default {
  getServices,
  getModello,
  creaService,
  modificaService,
  setDbInstallato
}
