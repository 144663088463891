<template>
  <div>
    <v-stepper v-model="e6" non-linear>
      <v-stepper-header>
        <v-stepper-step :complete="e6 > 1" step="1" editable>
          Creazione database
        </v-stepper-step>
        
<!--         <v-stepper-step :complete="e6 > 2" step="2" editable>
          Crea impostazioni
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="e6 > 3" step="3" editable>
          Prodotti di base
        </v-stepper-step>
        <v-divider></v-divider> -->
        <v-stepper-step step="2" editable>
          Configurazione
        </v-stepper-step>
        <v-divider></v-divider>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1">
          <database-item :databases="infoDbs" :baseDb="baseDb" :service="service" class="mb-5" @refresh="aggiornaElencoDb" @refreshParent='refreshParent'/>
          <v-row v-if="infoDbs.length === 0">
            <v-col cols="12" md="2">
            </v-col>
            <v-col cols="12" md="2">
              <v-btn depressed color="primary" @click="onCreaDbVuoti" :loading="vuotiLoading">
                Crea db Vuoti
              </v-btn>
            </v-col>
            <v-col cols="12" md="2">
              <v-btn depressed color="success" @click="onCopiaDbEsistenti" :loading="esistentiLoading">
                Copia da progetto esistente
              </v-btn>
              <v-text-field class="mt-3" v-model="sorgenteDb" label="Sorgente" outlined dense></v-text-field>
            </v-col>
<!--             <v-col cols="12" md="2">
              <v-btn depressed color="success" @click="onImportaBackup" :loading="importaBackupLoading">
                Importa backup
              </v-btn>
            </v-col>   -->          
          </v-row>
          <v-btn color="primary"  @click="e6++">
            Avanti
          </v-btn>
        </v-stepper-content>
<!--         <v-stepper-content step="2">
          <v-container fluid dense>
            <v-row justify="start">
             <v-spacer></v-spacer>
            </v-row>
          </v-container>  
          <v-btn color="primary"  @click="e6++">
            Avanti
          </v-btn>
          <v-btn  @click="e6--">
            Indietro
          </v-btn>
        </v-stepper-content> -->
<!--         <v-stepper-content step="3">
          <v-container fluid dense>
            <v-row justify="start">
              <v-spacer></v-spacer>
            </v-row>
          </v-container>  
          <v-btn color="primary"  @click="e6++">
            Avanti
          </v-btn>
          <v-btn  @click="e6--">
            Indietro
          </v-btn>
        </v-stepper-content> -->
        <v-stepper-content step="2">
          <v-container>
            <v-row dense>
              <v-col cols="12" md="12" sm="12">
                <v-card>
                  <v-card-title class="text-h5">
                   Configurazione parametri service
                  </v-card-title>
                  <v-card-subtitle>E' possibile scaricare il file da eseguire per la configurazione dell'ambiente service</v-card-subtitle>
                  <v-row dense>
                    <v-col cols="12" md="6" sm="12">
                      <v-list flat subheader three-line>
                        <v-list-item-group v-model="settings" multiple active-class="">
                          <v-list-item>
                            <template v-slot:default="{ active }">
                              <v-list-item-action>
                                <v-checkbox :input-value="active"></v-checkbox>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>Download programmi aggiornati</v-list-item-title>
                                <v-list-item-subtitle>Scarica gli ultimi aggiornamenti (eseguire solo se necessario)</v-list-item-subtitle>
                              </v-list-item-content>
                            </template>
                          </v-list-item>
                          <v-list-item>
                            <template v-slot:default="{ active }">
                              <v-list-item-action>
                                <v-checkbox :input-value="active"></v-checkbox>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>Installa i programmi aggiornati</v-list-item-title>
                                <v-list-item-subtitle>Installa gli ultimi aggiornamenti (se scaricati)</v-list-item-subtitle>
                              </v-list-item-content>
                            </template>
                          </v-list-item>
                          <v-list-item>
                            <template v-slot:default="{ active }">
                              <v-list-item-action>
                                <v-checkbox :input-value="active"></v-checkbox>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>Scarica i file di configurazione del cliente</v-list-item-title>
                                <v-list-item-subtitle>Necessari se viene modificato il cliente</v-list-item-subtitle>
                              </v-list-item-content>
                            </template>
                          </v-list-item>
                          <v-list-item>
                            <template v-slot:default="{ active }">
                              <v-list-item-action>
                                <v-checkbox :input-value="active"></v-checkbox>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>Cancella database locale</v-list-item-title>
                                <v-list-item-subtitle>Elimina il dabatase locale. E' necessario prima di scaricare un nuovo cliente</v-list-item-subtitle>
                              </v-list-item-content>
                            </template>
                          </v-list-item>
                        </v-list-item-group>                   
                      </v-list>
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                      <v-list>
                        <v-list-item-group v-model="settings_remoto" active-class=""> 
                          <v-list-item>
                            <template v-slot:default="{ active }">
                              <v-list-item-action>
                                <v-checkbox :input-value="active"></v-checkbox>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>Abilita modalità Remota</v-list-item-title>
                                <v-list-item-subtitle>I database configurati per il service sono gestiti solo da remoto. Non vengono scaricati in locale</v-list-item-subtitle>
                              </v-list-item-content>
                            </template>
                          </v-list-item>
                          <v-list-item>
                            <template v-slot:default="{ active }">
                              <v-list-item-action>
                                <v-checkbox :input-value="active"></v-checkbox>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>Disabilita modalità Remota</v-list-item-title>
                                <v-list-item-subtitle>I database vengono scaricati in locale</v-list-item-subtitle>
                              </v-list-item-content>
                            </template>
                          </v-list-item>
                        </v-list-item-group>                   
                      </v-list>                
                      <v-list>
                        <v-list-item-group v-model="settings_debug" active-class="">                       
                          <v-list-item>
                            <template v-slot:default="{ active }">
                              <v-list-item-action>
                                <v-checkbox :input-value="active"></v-checkbox>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>Abilita modalità Debug su database cliente (da specificare)</v-list-item-title>
                                <v-list-item-subtitle>Si accede in remoto ai dati del cliente. Attenzione le modifiche vengono ribaltate automaticamente al cliente</v-list-item-subtitle>
                                <v-list-item-action>
                                  <v-row no-gutters>
                                    <v-col cols="12" md="6">
                                      <v-text-field v-model="baseDbCliente" label="DB base name" dense placeholder="DB base name cliente (es. ubico_test01)" outlined @focus="settings_debug=1"></v-text-field>
                                    </v-col>
                                  </v-row>
                                </v-list-item-action>
                              </v-list-item-content>
                            </template>
                          </v-list-item>
                          <v-list-item>
                            <template v-slot:default="{ active }">
                              <v-list-item-action>
                                <v-checkbox :input-value="active"></v-checkbox>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>Disabilita modalità Debug</v-list-item-title>
                                <v-list-item-subtitle>Si torna a lavorare nei database configurati per il service</v-list-item-subtitle>
                              </v-list-item-content>
                            </template>
                          </v-list-item>
                        </v-list-item-group>                   
                      </v-list>                
                      <v-list>
                        <v-list-item-group v-model="settings_stampante" active-class="">                       
                          <v-list-item>
                            <template v-slot:default="{ active }">
                              <v-list-item-action>
                                <v-checkbox :input-value="active"></v-checkbox>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>Abilita stampante locale</v-list-item-title>
                                <v-list-item-subtitle>Viene utilizzata la stampante locale senza modificare le impostazioni nel database</v-list-item-subtitle>
                                <v-list-item-action>
                                  <v-row no-gutters>
                                    <v-col cols="12" md="6">
                                      <v-text-field v-model="ipStampante" label="IP stampante" dense placeholder="IP stampante locale (xxx.xxx.xxx.xxx)" outlined @focus="settings_stampante=1"></v-text-field>
                                    </v-col>
                                  </v-row>
                                </v-list-item-action>
                              </v-list-item-content>
                            </template>
                          </v-list-item>
                          <v-list-item>
                            <template v-slot:default="{ active }">
                              <v-list-item-action>
                                <v-checkbox :input-value="active"></v-checkbox>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>Disabilita stampante locale</v-list-item-title>
                                <v-list-item-subtitle>Viene utilizzata la stampante secondo le impostazioni nel database</v-list-item-subtitle>
                              </v-list-item-content>
                            </template>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-col>
                  </v-row>
                  <v-card-text></v-card-text>
                  <v-card-actions>
                    <v-btn class="ml-4 mb-2" link :href="'data:text/plain;charset=utf-8,' + encodeURIComponent(fileBatchContent)" download="service.bat">
                      Genera file configurazione
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
          <v-btn  @click="e6--">
            Indietro
          </v-btn>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <dialog-conferma
      :show="dialogConferma"
      :title="titoloConferma"
      :message="messaggioConferma"
      :onlyOk="onlyOk"
      :confirmText="confirmText"
      @dialog-confirm="onConferma"
      @dialog-cancel="dialogConferma=false; onlyOk=false"
    />
  </div>
</template>

<script>
  import licenze from '@/services/licenzeService'
  import services from '@/services/servicesService'
  import DialogConferma from '@/components/dialogConferma'
  import DatabaseItem from './databaseItem'
 /*  import SnippletElement from './snipplet.vue' */

  export default {
    components: {
      DialogConferma,
      DatabaseItem
/*       SnippletElement */
    },
    props: {
      service: Object
    },
    data (vm) {
      return {
         e6: 1,
         licenza: null,
         customerDbs: [],
         infoDbs:[],
         selectedCustomDb: 1,
         baseDb: '',
         apikey: '',
         sorgenteDb: '',
         dialogConferma: false,
         titoloConferma: '',
         messaggioConferma: '',
         onlyOk: false,
         onConferma: vm.creaDbVuoti,
         confirmText: 'SI',
         step2_ch1: false,
         step2_ch2: false,
         step3_ch1: false,
         step3_ch2: false,
         vuotiLoading: false,
         esistentiLoading: false,
         importaBackupLoading: false,
         settings: [2,3],
         settings_remoto: [],
         settings_debug: [],
         settings_stampante: [],
         baseDbCliente: '',
         ipStampante: ''
      }
    },
    async mounted() {
      let baseDb = null
      if (this.service) {
        this.apikey = this.service.apikey
        baseDb = this.service.baseDb
      }
      if (baseDb) {
        this.baseDb = baseDb
        await this.aggiornaElencoDb()
      }      
/*       let baseDb = null
      if (this.cliente && this.cliente.licenze) {
        const licenza = this.cliente.licenze[0]
        this.licenza = licenza
        baseDb = licenza.baseDb
      } else {
        baseDb = this.cliente.baseDb
      }
      if (baseDb) {
        this.baseDb = baseDb
        await this.aggiornaElencoDb()
      } */
    },
    computed: {
      impostazioni() {
        return this.service && this.service.impostazioni
      },
      fileBatchContent() {
        let parametri = []
        if (this.settings.includes(0)) parametri.push('download')
        if (this.settings.includes(1)) parametri.push('install')
        if (this.settings.includes(2)) parametri.push('env')
        if (this.settings.includes(3)) parametri.push('purgedb')
        if (this.settings_remoto === 0) parametri.push('+remote')
        if (this.settings_remoto === 1) parametri.push('-remote')
        if (this.settings_debug === 0) parametri.push(`+debug=${this.baseDbCliente}`)
        if (this.settings_debug === 1) parametri.push('-debug')
        if (this.settings_stampante === 0) parametri.push(`+printer=${this.ipStampante}`)
        if (this.settings_stampante === 1) parametri.push('-printer')
        parametri.push(`apikey=${this.service.apikey}`) 
        let cont = []
        cont.push('@echo Ubisell - Service')
        cont.push('@echo Avvio procedura impostazione service ...')
        cont.push('')
        cont.push('@echo Arresto servizi ...')
        cont.push('for /F %%i in (moduli.txt) do (')
        cont.push('	@nssm stop %%i')
        cont.push(')')
        cont.push('@nssm stop http-server')
        cont.push('@nssm stop http-server-bo')
        cont.push(`@ubisellsu service ${parametri.join(' ')}`)
        cont.push('')
        cont.push('for /F %%i in (moduli.txt) do (')
        cont.push('  @nssm start %%i')
        cont.push(')')
        cont.push('@nssm start http-server')
        cont.push('@nssm start http-server-bo')
        cont.push('')
        cont.push('@echo Procedura impostazione service completata')
        cont.push('@pause')

        return cont.join('\n')
      }      
    },
    methods: {
      operazioneEseguita() {
        this.titoloConferma = 'Operazione eseguita'
        this.messaggioConferma = 'Operazione eseguita con successo !'
        this.onlyOk = true
        this.onConferma = this.operazioneEseguitaConfirm
        this.confirmText = 'OK'
        this.dialogConferma = true
      },
      operazioneEseguitaConfirm() {
        this.dialogConferma = false
        this.confirmText= 'SI'
        this.onlyOk = false
      },
      async aggiornaElencoDb() {
        const data = await licenze.listCustomerDatabaseWithInfo(this.baseDb)
        this.customerDbs = data.dbs
        this.infoDbs = data.info.result
      },
      onCreaDbVuoti() {
        this.titoloConferma = 'Creazione nuovi database'
        this.messaggioConferma = 'Confermi creazione database vuoti per il progetto ?'
        this.onConferma = this.creaDbVuoti
        this.dialogConferma = true
      },
      async creaDbVuoti() {
        if (this.baseDb) {
          this.vuotiLoading = true
          await licenze.creaDbVuoti(this.baseDb)
          await services.setDbInstallato(this.service.codice, '')
          await this.aggiornaElencoDb()
          this.operazioneEseguita()
          this.refreshParent()
          this.vuotiLoading = false
        }
      },
      onCopiaDbEsistenti() {
        if (this.sorgenteDb) {
          this.titoloConferma = 'Creazione nuovi database'
          this.messaggioConferma = `Confermi copia database dal progetto ${this.sorgenteDb} ?`
          this.onConferma = this.copiaDbEsistenti
          this.dialogConferma = true
        }
      },
      async copiaDbEsistenti() {
        this.dialogConferma = false
        if (this.baseDb && this.sorgenteDb) {
          this.esistentiLoading = true
          await licenze.copiaDbEsistenti(this.baseDb, this.sorgenteDb)
          await services.setDbInstallato(this.service.codice, this.sorgenteDb)
          await this.aggiornaElencoDb()
          this.refreshParent()
          this.operazioneEseguita()
          this.esistentiLoading = false
        }
      },
      onImportaBackup() {
        this.titoloConferma = 'Importazione backup'
        this.messaggioConferma = `E' necessario creare i database vuoti e poi scegliere i backup da importare per ciascun database<br/>E' possibile selezionare contemporaneamente i file per più database`
        this.onlyOk = true
        this.onConferma = this.operazioneEseguitaConfirm
        this.confirmText = 'OK'
        this.dialogConferma = true
      },
      refreshParent() {
        this.$emit('refresh')
      }
    }
  }
</script>
