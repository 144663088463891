<template>
  <div>
    <v-data-table
      v-model="selected"
      :show-select="totaleServices > 0"
      item-key="codice"
      :headers="headers"
      :items="servicesSorted"
      :options.sync="options"

      :loading="loading"
      class="elevation-1"
      @click:row="clickRow"
      @dblclick:row="dblclickRow"
      :disable-sort="true"
      :footer-props="{
        itemsPerPageOptions: [5, 10, 15, 50, 100]
      }"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      show-expand
    >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Services e Licenze</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="primary" dark class="mb-2" @click="onNuovoService">Nuovo Service</v-btn>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Confermi cancellazione service?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">SI</v-btn>
              <v-btn color="blue darken-1" text @click="closeDelete">NO</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <dialog-errore :show="dialogErrore" title="Errore aggiornamento Service" />
      </v-toolbar>
    </template>
     <template v-slot:[`item.installato.data`]="{ item }">
      {{item.installato.data | fullDate}}
    </template>
 <!--   <template v-slot:[`item.attivo`]="{ item }">
      <v-simple-checkbox v-model="item.attivo" disabled />
    </template> -->
    <template v-slot:[`item.actions`]="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon small class="mr-2" @click="editItem(item)" v-bind="attrs" v-on="on">mdi-pencil</v-icon>
        </template>
        <span>Modifica il service</span>
      </v-tooltip>
<!--       <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon small class="mr-2" @click="duplicateItem(item)" v-bind="attrs" v-on="on">mdi-content-duplicate</v-icon>
        </template>
        <span>Duplica il service</span>
      </v-tooltip> -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon small @click="deleteItem(item)" v-bind="attrs" v-on="on">mdi-delete</v-icon>
        </template>
        <span>Cancella il service</span>
      </v-tooltip>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="getDataFromApi">Ricarica</v-btn>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length" class="servicesSelected">
        <v-tabs dense v-model="tab">
          <!-- <v-tab class="servicesSelected">Licenze{{item.name}}</v-tab> -->
          <v-tab class="servicesSelected">Database</v-tab>
<!--           <v-tab class="servicesSelected">Migrazione</v-tab>
          <v-tab class="servicesSelected">Utility</v-tab -->
        </v-tabs>
        <v-tabs-items v-model="tab">
<!--           <v-tab-item>
            <dettaglio-licenze :licenze="item.licenze" :codiceService="item.codice" @update-licenza="updateLicence" @new-licenza="newLicence"/>
          </v-tab-item> -->
          <v-tab-item>
            <dettaglio-database :service="item" @refresh="refresh"/>
          </v-tab-item>
<!--           <v-tab-item>
            <dettaglio-migrazione :service="item" />
          </v-tab-item>
          <v-tab-item>
            <dettaglio-utility :service="item" />
          </v-tab-item> -->
          <v-tab-item></v-tab-item> <!-- Step Procedura setup + Stampa lista lavoro (apre pagina stampabile con le info per l'installazione e le procedura da effettaure) -->
          <v-tab-item>
          </v-tab-item>
        </v-tabs-items>
      </td>
    </template>    
    </v-data-table>
    <!-- <speed-dial /> -->
  </div>
</template>

<script>
  import { verificaLicenza } from '@/mixins/verificaLicenza.js'
  import { dynamicSortMultiple } from '@/components/helpers.js'
  import { rivenditore } from '@/mixins/rivenditore.js'
  import DialogErrore from '@/components/dialogErrore'
/*   import SpeedDial from './speedDial.vue'
  import Filtri from './filtri'
  import Recapiti from './elementi/recapiti'
  import Fatture from './elementi/fatture' */

/*   import DettaglioLicenze from './elementi/licenze' */
  import DettaglioDatabase from './elementi/database'
/*   import DettaglioMigrazione from './elementi/migrazione'
  import DettaglioUtility from './elementi/utility' */

  import services from '@/services/servicesService.js'
  import licenze from '@/services/licenzeService.js'
  
  export default {
    components: {
      DialogErrore,
      DettaglioDatabase
    },
    mixins: [
      verificaLicenza,
      rivenditore
    ],
    data () {
      return {
        totaleServices: 0,
        services: [],
        selected: [],
        loading: true,
        options: {},
        headers: [
          { text: 'Codice', value: 'codice', align: 'start' },
          { text: 'Descrizione', value: 'descrizione' },
          // { text: 'Azienda', value: 'azienda' },
          { text: 'Base db', value: 'baseDb' },
          { text: 'Installato', value: 'installato.baseDb' },
          { text: 'Inst. data', value: 'installato.data' },
          { text: 'Actions', value: 'actions', sortable: false },
          { text: '', value: 'data-table-expand' }
        ],
        expanded: [],
        singleExpand: false,
        dialogErrore: false,
        dialogDelete: false,
        editedIndex: -1,
        editedItem: null,
        defaultItem: null,
        filtri: null,
        tab: null
      }
    },
    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nuovo Service' : 'Modifica Service'
      },
      serviceSaved() {
        return this.$store.getters['services/saved']
      },
      refreshList() {
        return this.$store.getters['services/refresh']
      },
      servicesSorted() {
        const els = this.services
        const elSorted = els.sort(dynamicSortMultiple('descrizione'))
        return elSorted
      }
    },
    watch: {
      options: {
        async handler () {
          await this.getDataFromApi()
        },
        deep: true
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
      async serviceSaved(value) {
        if (value) {
          await this.save()
        }
      },
      async refreshList(value) {
        if (value) {
          await this.getDataFromApi()
          this.$store.commit('services/CLEAR_REFRESH')
        }
      }
    },
    async created() {
      this.defaultItem = await services.getModello()
    },
    async mounted () {
      await this.verificaModuliLocali()
      if (!this.$store.getters['utenti/isLoggedIn']) {
        this.$router.replace('/')
      }      
      await this.getDataFromApi()
    },
    methods: {
      async refresh() {
        await this.getDataFromApi()
      },
      async onRicerca(filtri) {
        this.filtri = filtri
        await this.getDataFromApi()
      },
      async getDataFromApi () {
        try {
          this.loading = true
          const servicesRaw = await services.getServices()
          // this.servicesRaw = data && data.total_rows > 0 ? data.rows.map(x => x.doc) : []
          // this.servicesRaw = data && data.total_rows > 0 ? data.rows : []
          this.services = this.datiVisualizzazione(servicesRaw)
          this.totaleServices = servicesRaw.length
        } catch(err) {
          this.services = []
          this.totaleServices = 0
        } finally {
          this.loading = false
        }
      },
      onNuovoService() {
        this.editedIndex = -1
        this.editedItem = Object.assign({}, this.defaultItem)
        this.$store.commit('services/SET_SERVICE', this.editedItem)
        this.$store.commit('SET_RIGHT_DRAWER_READ_ONLY', false)
        this.$store.commit('SET_RIGHT_DRAWER', true)
      },
      editItem (item) {
        this.editedIndex = this.services.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.$store.commit('services/SET_SERVICE', this.editedItem)
        this.$store.commit('SET_RIGHT_DRAWER_READ_ONLY', false)
        this.$store.commit('SET_RIGHT_DRAWER', true)
      },
      duplicateItem(item) {
        this.editedIndex = -1
        this.editedItem = Object.assign({}, item)
        this.editedItem._id = ''
        delete this.editedItem._rev
        this.$store.commit('services/SET_SERVICE', this.editedItem)
        this.$store.commit('SET_RIGHT_DRAWER_READ_ONLY', false)
        this.$store.commit('SET_RIGHT_DRAWER', true)
      },
      deleteItem (item) {
        this.editedIndex = this.services.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      async deleteItemConfirm () {
        this.services.splice(this.editedIndex, 1)
        // TODO: ATTENZIONE VA VERIFICATO CHE NON ABBIA STORIA
        if (!await services.cancellaService(this.editedItem)) {
          this.dialogErrore = true
        }
        this.closeDelete()
      },

      close () {
        this.$store.commit('SET_RIGHT_DRAWER', false)
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      async save () {
        this.$store.commit('services/CLEAR_SERVICE_SAVED')
        this.editedItem = this.$store.getters['services/service']
        if (this.editedIndex > -1) {
          this.editedItem = this.deleteDatiVisualizzazione(this.editedItem)
          Object.assign(this.services[this.editedIndex], this.editedItem)
          if (!await services.modificaService(this.editedItem)) {
            this.dialogErrore = true
            return
          }
        } else {
          let service = this.editedItem
          service.reseller = this.getReseller()          
          this.services.push(service)
          if (!await services.creaService(service)) {
            this.dialogErrore = true
            return
          }
        }
        this.close()
        // va fatto il refresh della lista per prendere gli aggiornamenti
        await this.getDataFromApi()
      },
      erroreConfirm() {
        this.dialogErrore = false
      },
      clickRow(item, options) {

      },
      dblclickRow(event, options) {
        const item = options.item
        this.editedIndex = this.services.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.$store.commit('services/SET_SERVICE', this.editedItem)
        this.$store.commit('SET_RIGHT_DRAWER_READ_ONLY', true)
        this.$store.commit('SET_RIGHT_DRAWER', true)
      },
      datiVisualizzazione(services) {
        const elaborati = services.map(el => {
          const recapito = el.recapiti && el.recapiti.length > 0 ? el.recapiti.find(x => x.tipo.codice === 'principale') : null
          const indirizzo = recapito ? `${recapito.indirizzo}, ${recapito.localita} ${recapito.cap} - ${recapito.provincia}` : ''
          const contatto = el.contatti && el.contatti.length > 0 ? el.contatti.find(x => x.tipo === 'titolare') : null
          const titolare = contatto ? `${contatto.nome} ${contatto.cognome} - cell.${contatto.cellulare} - email: ${contatto.email}` : ''
          return { ...el, c_recapito: recapito, c_indirizzo: indirizzo, c_contatto: contatto, c_titolare: titolare }
        }) 
        return elaborati
      },
      deleteDatiVisualizzazione(service) {
        let locService = {...service}
        // Eventuale aggiornamento dati modificati
        delete locService.c_recapito
        delete locService.c_indirizzo
        delete locService.c_contatto
        delete locService.c_titolare
        return locService
      },
      async newLicence(codiceService, licenza) {
        await licenze.creaLicenza(codiceService, licenza)
      },
      async updateLicence(codiceService, licenza) {
        await licenze.aggiornaLicenza(codiceService, licenza)
      }
    }
  }
</script>

<style lang="scss">
  tr.v-data-table__expanded__content  { // TODO: Solo su questa pagina !
    box-shadow: none !important;
  }
  
  td.servicesSelected {
    border-style: solid!important;
    border-width: 2px!important;
    border-color: #1976d2 !important;
  }

</style>
