var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"show-select":_vm.totaleServices > 0,"item-key":"codice","headers":_vm.headers,"items":_vm.servicesSorted,"options":_vm.options,"loading":_vm.loading,"disable-sort":true,"footer-props":{
        itemsPerPageOptions: [5, 10, 15, 50, 100]
      },"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"show-expand":""},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.clickRow,"dblclick:row":_vm.dblclickRow,"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Services e Licenze")]),_c('v-spacer'),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.onNuovoService}},[_vm._v("Nuovo Service")]),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Confermi cancellazione service?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("SI")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("NO")]),_c('v-spacer')],1)],1)],1),_c('dialog-errore',{attrs:{"show":_vm.dialogErrore,"title":"Errore aggiornamento Service"}})],1)]},proxy:true},{key:"item.installato.data",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("fullDate")(item.installato.data))+" ")]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-pencil")])]}}],null,true)},[_c('span',[_vm._v("Modifica il service")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-delete")])]}}],null,true)},[_c('span',[_vm._v("Cancella il service")])])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.getDataFromApi}},[_vm._v("Ricarica")])]},proxy:true},{key:"expanded-item",fn:function(ref){
      var headers = ref.headers;
      var item = ref.item;
return [_c('td',{staticClass:"servicesSelected",attrs:{"colspan":headers.length}},[_c('v-tabs',{attrs:{"dense":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{staticClass:"servicesSelected"},[_vm._v("Database")])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('dettaglio-database',{attrs:{"service":item},on:{"refresh":_vm.refresh}})],1),_c('v-tab-item'),_vm._v(" "),_c('v-tab-item')],1)],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }